import { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./routes/router";
import Loader from "./components/loader";



function App() {

  return (
    <Suspense
      fallback={
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh' // Full viewport height
        }}>
          <Loader />
        </div>
      }
    >
      <RouterProvider router={routes} />
    </Suspense>
  );
}

export default App;
