import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/nurselogo.jpeg";
import { useAuthStore } from "../store/auth";

function Navbar() {
  const logOut = useAuthStore((state) => state.logOut);

  return (
    <nav className="bg-white shadow-lg border-radius-4">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0">
              {/* Logo */}
              <Link to="/" className="flex items-center">
                <img className="h-12 w-auto" src={logo} alt="Logo" />
              </Link>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">
            {/* Logout button */}
            <button
              type="button"
              onClick={logOut}
              className="bg-white rounded-full p-2 inline-flex items-center justify-center text-base-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-base-100 focus:ring-white"
            >
              {/* Logout icon */}
              <svg
                viewBox="0 0 1024.00 1024.00"
                xmlns="http://www.w3.org/2000/svg"
                fill="#000000"
                stroke="#000000"
                stroke-width="0.01024"
                width={30}
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    fill="#ad0000"
                    d="M352 159.872V230.4a352 352 0 1 0 320 0v-70.528A416.128 416.128 0 0 1 512 960a416 416 0 0 1-160-800.128z"
                  ></path>
                  <path
                    fill="#ad0000"
                    d="M512 64q32 0 32 32v320q0 32-32 32t-32-32V96q0-32 32-32z"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
