import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function FormsLayout({ children }) {
  const [pageHeader, setPageHeader] = useState("");
  const { pathname } = useLocation();

  useEffect(() => {
    setPageHeader(pathname.split("/")[2]); // Updated to get the correct part of the path
  }, [pathname]);

  return (
    <div className="border w-[100%]  mx-auto bg-white shadow-lg rounded-lg p-10 flex justify-center  flex-col">
      <div>
        <h2  className="text-xl  font-bold" style={{textAlign:"center"}}>{pageHeader}</h2>
        <div className="border-b-2 border-redhunt my-2"></div>
      </div>
      <div className="w-full">{children}</div>
    </div>
  );
}

export default FormsLayout;
